<main id="main">
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 col-xl-10 col-12 mob-mb1">
        </div>
        <div class="col-lg-2 col-xl-2 col-12 mob-mb1" style="float: right;">
          <a routerLink="/boss-user/project-list" class="btn btn-primary text-dark">
            Back</a>
        </div>
      </div>
      <div class="table-responsive mt-5 mb-3">
        <table class="table table-striped align-middle">
          <thead>
            <tr>
              <th width="100px">Project Title</th>
              <!-- <th>Project Description</th> -->
              <th>BOS ID</th>
              <th>Publish Date</th>
              <th>Category</th>
              <th>Industry</th>
              <th>Min value (GBP)</th>
              <th>Max value (GBP)</th>
              <th>Website</th>
              <th>Link to notice</th>
              <th>Created Date</th>
              <th>Due Date & Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><a (click)="projectDetails(projectDetails?._id)"
                  href="javascript:;">{{projectDetails?.projectName}}</a></td>
              <!-- <td><a title="{{ projectDetails?.description }}">{{ projectDetails?.description | slice:0:10 }}</a></td> -->
              <td v-align="middle">{{projectDetails?.BOSID}}</td>
              <td>{{projectDetails?.publishDate | date:'dd/MM/yyyy'}}</td>
              <td>{{projectDetails?.category}}</td>
              <td>{{projectDetails?.industry}}</td>
              <td>£ {{projectDetails?.minValue}}</td>
              <td>£ {{projectDetails?.maxValue}}</td>
              <td><a>{{projectDetails?.website}}</a></td>
              <td><a href='{{projectDetails?.link}}' target="_blank">{{projectDetails?.link}}</a></td>
              <td>{{projectDetails?.createdAt | date:'dd/MM/yyyy'}}</td>
              <td>{{projectDetails?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
              <td>
                <a class="btn btn-primary btn-sm" style="cursor: pointer;"
                  (click)="editProjectDetails(projectDetails?._id)"><i class="bi bi-pencil-fill"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Project Description</strong></p>
        </div>
      </section><br>

      <p>{{ projectDetails?.description }}</p>

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Client & FOI Detils</strong></p>
        </div>
      </section>

      <div class="row mt-5">
        <div class="col-lg-4 col-xl-4 col-12 mb-4">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-4 col-xl-4 col-12">
                  <h6 class="text-theme"><strong>Client Detils</strong></h6>
                </div>
                <div class="col-lg-7 col-xl-7 col-12">
                  <p class="p-13 mb-0">Mail ID : {{projectDetails?.mailID}}</p>
                  <p class="p-13 mb-0">Client Type : {{projectDetails?.clientType}}</p>
                  <p class="p-13 mb-0">Client Name : {{projectDetails?.clientName}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-4 col-12 mb-4">
          <div class="row align-items-center">
            <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
              <p class="mb-0">FOI Screenshot</p>
            </div>
            <div class="row align-items-center" *ngIf="projectDetails?.mailScreenshots?.length == 0">
              <div class="col-lg-12 col-xl-12 col-12 mob-mb1">
                <p class="mb-0">No Record Found.</p>
              </div>
            </div>
            <ng-container *ngIf="projectDetails?.mailScreenshots?.length > 0">
              <div class="row align-items-center" *ngFor="let foiScreenshot of projectDetails?.mailScreenshots">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                  <p class="mb-0">{{ foiScreenshot?.name }}</p>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a (click)="openDocument(foiScreenshot)" class="btn btn-small w-100" data-bs-toggle="modal"
                    data-bs-target="#ViewScreenshot"><i class="bi bi-eye-fill"></i> View</a>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a (click)="
                                download(foiScreenshot?.link?.url, foiScreenshot?.name)
                              " target="_blank" class="btn btn-small w-100"><i
                      class="bi bi-file-earmark-arrow-down-fill"></i>
                    Download</a>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
              <p class="mb-0">FOI Document</p>
            </div>
            <div class="row align-items-center" *ngIf="projectDetails?.fois?.length == 0">
              <div class="col-lg-12 col-xl-12 col-12 mob-mb1">
                <p class="mb-0">No Record Found.</p>
              </div>
            </div>
            <ng-container *ngIf="projectDetails?.fois?.length > 0">
              <div class="row align-items-center" *ngFor="let foiDocument of projectDetails?.fois">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                  <p class="mb-0">{{ foiDocument?.name }}</p>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a (click)="openDocument(foiDocument)" class="btn btn-small w-100" data-bs-toggle="modal"
                    data-bs-target="#ViewDocumentModal"><i class="bi bi-eye-fill"></i> View</a>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a style="
                                padding-left: 0 !important;
                                padding-right: 0px !important;
                              " (click)="
                                download(foiDocument?.link?.url, foiDocument?.name)
                              " target="_blank" class="btn btn-small w-100"><i
                      class="bi bi-file-earmark-arrow-down-fill"></i>
                    Download</a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-4 col-xl-4 col-12 mb-4">
          <div class="card">
            <div class="card-body text-center">
              <p class="p-13"><strong>Period of Contract: {{projectDetails?.periodOfContractStart | date :
                  'dd/MM/yyyy'}} - {{projectDetails?.periodOfContractEnd | date :
                  'dd/MM/yyyy'}}</strong></p>
              <p class="p-13"><strong>Project Type: {{projectDetails?.projectType}}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<!-- View Screenshot Modal -->
<div class="modal fade" id="ViewScreenshot" tabindex="-1" aria-labelledby="ViewScreenshotLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewScreenshotLabel">FOI Screenshot {{selectedDocument?.name}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img [src]="selectedDocument?.link?.url" class="img-fluid" />
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewDocumentModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between;">
        <h5 class="modal-title" id="ViewDocumentModalLabel">FOI Document ({{selectedDocument?.name}})</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isPdf(selectedDocument?.link?.url); else otherFormats">
          <ngx-extended-pdf-viewer [src]="selectedDocument?.link?.url" height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormats>
          <iframe *ngIf="isWordOrExcel(selectedDocument?.link?.url)"
            [src]="getDocumentViewerUrl(selectedDocument?.link?.url)" style="width: 100%; height: 600px;"></iframe>
          <img *ngIf="isImage(selectedDocument?.link?.url)" [src]="selectedDocument?.link?.url" class="img-fluid" />
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>