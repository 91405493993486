<body class="bg-blue">

    <!-- ======= Header ======= -->

    <section class="pageheader pt-5">
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-2">
                    <a href="href:;" class="btn btn-primary text-dark">Back</a>
                </div>
            </div>
        </div>
    </section>
    <main id="main">
        <section>
            <div class="container">
                <div class="row mb-4 justify-content-center">
                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-0"> Question Name </p>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-0"> Question Name </p>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-0"> Reference Doscument & Page number </p>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-0"> Weightage </p>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-0"> Deadline </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-xl-8 col-md-8 col-sm-8 col-12">
                        <div class="card">
                            <div class="card-body">
                                <p> <strong>Instructions from West Gate/ Spectrum</strong> </p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia
                                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <p> <strong>Review Comments</strong> </p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia
                                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h6><strong>Supplier Response</strong></h6>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="form-group">
                                <input type="text" class="form-control" value="V1">
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="form-group">
                                <input type="text" class="form-control" value="Review Comments">
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-11">
                            <div class="form-group">
                                <input type="text" class="form-control" value="V2">
                            </div>
                        </div>
                        <div class="col-auto text-end">
                            <button type="button" class="btn btn-outline-white text-dark bg-white"><i
                                    class="bi bi-pencil-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main><!-- End #main -->

</body>