<main id="main">
    <section>
        <div class="container">
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th width="200px">Project Title</th>
                            <th>BOS ID</th>
                            <th>Publish Date</th>
                            <th>Category</th>
                            <th>Industry</th>
                            <th>Value</th>
                            <th>Project Type</th>
                            <th>Status</th>
                            <th>Due Date &amp; Time</th>
                            <th></th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a href="javascript:;">Provision of MyBedsLife Student Engagement App Support Services</a></td>
                            <td v-align="middle">546552</td>
                            <td>2023-05-31</td>
                            <td>Website</td>
                            <td>School</td>
                            <td>£50000</td>
                            <td>Development</td>
                            <td>
                                <select class="form-select-sm">
                                    <option>Completed</option>
                                    <option>In-Progress</option>
                                </select>
                            </td>
                            <td>25-11-2014 & 5:00PM</td>
                            <td><i class="bi bi-check-circle-fill text-success"></i> CS(3/3)</td>
                            <td>
                                <a href="" class="btn btn-sm btn-dark" title="View Details">Shortlist</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <section class="p-0">
        <div class="container">
            <div class="row mb-4">
                <h4 class="text-theme"><strong>Project Description</strong></h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <h4 class="text-theme mb-4"><strong>Documents</strong></h4>
                </div>
                <div class="col-lg-6 col-xl-6 col-md-6 col-12 mob-mb1">
                    <h5>Client Documents</h5>
                    <div class="card">
                        <ul class="project-document">
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">ITT</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">SQ</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">T&C</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">Specification</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">Notice</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-md-6 col-12">
                    <h5>West Gate Documents</h5>
                    <div class="card">
                        <ul class="project-document">
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">Project Notice</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">Ownership Tracker File</p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="javascript:;" class="btn btn-primarysm"><i class="bi bi-eye-fill"></i> View</a>
                                        <a href="javascript:;" class="btn btn-primarysm"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-xl-4 col-12">
                                    <h6 class="text-theme"><strong>Client Detils</strong></h6>
                                </div>
                                <div class="col-lg-7 col-xl-7 col-12">
                                    <p class="p-13 mb-0">Mail ID : abc@gmail.com</p>
                                    <p class="p-13 mb-0">Client Type : Private Sector</p>
                                    <p class="p-13 mb-0">Client Name : XYZ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                            <p class="mb-0">FOI Screenshot</p>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-6">
                            <a href="" class="btn btn-small w-100" data-bs-toggle="modal"
                                data-bs-target="#ViewScreenshot"><i class="bi bi-eye-fill"></i> View</a>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-6">
                            <a href="" class="btn btn-small w-100" data-bs-toggle="modal"
                                data-bs-target="#FIOScreenshot"><i class="bi bi-file-earmark-arrow-up-fill"></i>
                                Upload</a>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">
                        <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                            <p class="mb-0">FOI Document</p>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-6">
                            <a href="" class="btn btn-small w-100"><i class="bi bi-eye-fill"></i> View</a>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-6">
                            <a href="" class="btn btn-small w-100"><i class="bi bi-file-earmark-arrow-up-fill"></i>
                                Upload</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <div class="card">
                        <div class="card-body text-center">
                            <p class="p-13"><strong>Period of Contract: Start Date - End Date</strong></p>
                            <p class="p-13"><strong>Project Type: Developement</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h5 class="text-white mb-4"><strong>Summary Note Questions</strong></h5>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8 col-12 mb-4">
                    <table class="table table-dark table-borderless" style="background-color: #252525;">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Weightage</th>
                                <th>Status</th>
                                <th>Deadline</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="350">1. Project management approach and project team outlined. Resilience –
                                    ability to deliver.</td>
                                <td>35%</td>
                                <td>Not Verified</td>
                                <td>01-02-2014</td>
                            </tr>
                            <tr>
                                <td width="350">1. Project management approach and project team outlined. Resilience –
                                    ability to deliver.</td>
                                <td>35%</td>
                                <td>Not Verified</td>
                                <td>01-02-2014</td>
                            </tr>
                            <tr>
                                <td width="350">1. Project management approach and project team outlined. Resilience –
                                    ability to deliver.</td>
                                <td>35%</td>
                                <td>Not Verified</td>
                                <td>01-02-2014</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <table class="table table-dark table-borderless">
                        <thead>
                            <tr>
                                <th>Review Note</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> dolor sit amet, consectetur......</td>
                                <td><a href="question-details.html" class="btn btn-small w-100"><i
                                            class="bi bi-eye-fill"></i> View Question</a></td>
                            </tr>
                            <tr>
                                <td> dolor sit amet, consectetur......</td>
                                <td><a href="question-details.html" class="btn btn-small w-100"><i
                                            class="bi bi-eye-fill"></i> View Question</a></td>
                            </tr>
                            <tr>
                                <td> dolor sit amet, consectetur......</td>
                                <td><a href="question-details.html" class="btn btn-small w-100"><i
                                            class="bi bi-eye-fill"></i> View Question</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container text-center">
            <button class="btn btn-primary w-25">Apply</button>
        </div>
    </section>
</main><!-- End #main -->