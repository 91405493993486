<!-- <div class="modal-dialog" role="document" style="min-width: 100%; margin: 5px !important; padding: 10px !important;">
    <div class="modal-content" style="width: 100%;">

        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload Excel File</h5>
            <button type="button" style="float: right !important;" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body p-0">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="modal-lg" role="document">
    <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="uploadModalLabel">Upload Excel File</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="file-upload-wrapper">
                            <label for="file-upload" class="btn btn-secondary btn-lg">
                                <i class="bi bi-cloud-upload"></i> Choose File
                            </label>
                            <input id="file-upload" type="file" (change)="onFileChange($event)" accept=".xlsx, .xls"
                                class="d-none" />
                        </div>
                        <small class="text-muted d-block mt-2">Accepted formats: .xlsx, .xls</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>