import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ProjectService } from 'src/app/services/project-service/project.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectManagerService } from 'src/app/services/project-manager/project-manager.service';
@Component({
  selector: 'app-match-project-details',
  templateUrl: './match-project-details.component.html',
  styleUrls: ['./match-project-details.component.scss']
})
export class MatchProjectDetailsComponent {


  @ViewChild('downloadLink') private downloadLink!: ElementRef;

  showLoader: boolean = false;
  projectDetails: any = [];
  projectId: string = '';
  projectID: any;
  dateDifference: any;
  currentDate: Date = new Date();
  selectedDocument: any;
  loginUser: any;
  summaryQuestionList: any;
  selectedDate: any;
  summaryId: any;
  casestudylist: any = [];
  selectedSupplier: any;
  userDetail: any;
  selectedSuppliers: { [key: string]: { company: string; startDate: any } } = {};

  statusList: string[] = [
    'In solution',
    'In-review',
    'In-Submission',
    'Submitted',
    'Awarded',
    'Not awarded',
    'Dropped'
  ];

  companyDetails: any = [
    {
      name: "Delphi Services Limited"
    }, {
      name: "Spectrum IT Hub Limited"
    }, {
      name: "Apex IT Solutions"
    }, {
      name: "Big Data Limited"
    }, {
      name: "Saiwen"
    }
  ]

  constructor(
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private summaryService: SummaryService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private projectManagerService: ProjectManagerService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.projectId = params['id']
    });

    this.loginUser = this.localStorageService.getLogger();
  }

  ngOnInit(): void {
    this.getProjectDetails();
    this.getSummaryQuestion();
    this.getUserDetails();
  }

  getProjectDetails() {
    this.showLoader = true;
    this.projectService.getProjectDetailsById(this.projectId).subscribe((response) => {
      if (response?.status == true) {
        this.showLoader = false;
        this.projectDetails = response?.data;
        this.casestudylist = response?.data?.casestudy;
      } else {
        this.notificationService.showError(response?.message);
        this.showLoader = false;
      }
    }, (error) => {
      this.notificationService.showError(error?.message);
      this.showLoader = false;
    });
  }

  getUserDetails() {
    this.projectManagerService.getUserListProjectManager('SupplierAdmin' , this.projectId).subscribe((response) => {
      if (response?.status == true) {
        this.showLoader = false;
        this.userDetail = response?.data;
        this.selectedSuppliers = this.userDetail.reduce((acc: any, supplier: any) => {
          acc[supplier._id] = { company: '', startDate: null };
          return acc;
        }, {});
      } else {
        this.notificationService.showError(response?.message);
        this.showLoader = false;
      }
    }, (error) => {
      this.notificationService.showError(error?.message);
      this.showLoader = false;
    });
  }

  selectSupplier(supplier: any) {
    this.selectedSupplier = supplier

    if (!this.selectedSupplier) {
      return this.notificationService.showError('please select supplier');
    }
    console.log('sadsdd', supplier);

    const data = {
      select: {
        supplierId: this.selectedSupplier?._id,
        companySelect: supplier.company,
        handoverCall: supplier.startDate
      }
    }
    this.projectManagerService.dropUser(data, this.projectId).subscribe((response) => {
      this.notificationService.showSuccess('Successfully select user')
    }, (error) => {
      this.notificationService.showError(error?.message || 'Something went wrong');
    });
  }

  dropUser(details: any) {
    console.log('this is testing data', details);
    if (!details?.reason) {
      return this.notificationService.showError('Please enter reason');
    }

    const data = {
      dropUser: {
        userId: details?._id,
        reason: details?.reason
      }
    }

    this.projectManagerService.dropUser(data, this.projectId).subscribe((response) => {
      if (response?.status == true) {
        this.notificationService.showSuccess(response?.message || 'Drop user successfully');
        //this.getUserDetails();
      } else {
        return this.notificationService.showError('Try after some time.');
      }
    }, (error) => {
      this.notificationService.showError(error?.message || 'Error.')
    })
  }


  getSummaryQuestion() {
    this.showLoader = true;
    this.projectService.getSummaryQuestionList(this.projectId).subscribe((response) => {
      if (response?.status == true) {
        this.showLoader = false;
        this.summaryQuestionList = response?.data;
      } else {
        this.notificationService.showError(response?.message);
        this.showLoader = false;
      }
    }, (error) => {
      this.notificationService.showError(error?.message);
      this.showLoader = false;
    });
  }

  openDocument(data: any) {
    this.selectedDocument = data;
  }

  download(imageUrl: string, fileName: string): void {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // You can customize the filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  editSummaryDate(date: any, summaryId: string) {
    this.summaryId = summaryId
    this.selectedDate = this.datePipe.transform(date, 'yyyy-MM-dd', 'en-US');
  }

  editUserName() {
    console.log('editUserName :');
  }
  saveDeadLineDate() {
    let payload = {
      deadline: this.selectedDate
    }
    this.summaryService.updateSummaryDetail(this.summaryId, payload).subscribe((response) => {
      if (response?.status == true) {
        this.showLoader = false;
        this.getSummaryQuestion()
      } else {
        this.notificationService.showError(response?.message);
        this.showLoader = false;
      }
    }, (error) => {
      this.notificationService.showError(error?.message);
      this.showLoader = false;
    });
  }

  onStatusChange(supplier: any) {
    const payload = {
      projectId: this.projectDetails?._id,
      supplierId: supplier?.supplierId,
      supplierStatus: supplier?.supplierDetails?.status
    };

    this.changeSupplierStatus(payload);
  }

  changeSupplierStatus(payload: any) {
    this.projectService.changeProjectSupplierStatus(payload).subscribe((response) => {
      if (response?.status == true) {
        this.showLoader = false;
        this.notificationService.showSuccess(response?.message);
        this.getSummaryQuestion();
      } else {
        this.notificationService.showError(response?.message);
        this.showLoader = false;
      }
    }, (error) => {
      this.notificationService.showError(error?.message);
      this.showLoader = false;
    });
  }

  openSummaryDetail(item: any) {
    localStorage.setItem('ViewSummary', JSON.stringify(item));
    this.router.navigate(['/project-manager/project/summary-project-details']);
  }

  isPdf(url: string): boolean {
    return url?.endsWith('.pdf') || false;
  }

  isWordOrExcel(url: string): boolean {
    return url?.endsWith('.doc') || url?.endsWith('.docx') || url?.endsWith('.xls') || url?.endsWith('.xlsx') || false;
  }

  isImage(url: string): boolean {
    return url?.endsWith('.jpg') || url?.endsWith('.jpeg') || url?.endsWith('.png') || false;
  }

  getDocumentViewerUrl(url: string): SafeResourceUrl {
    if (this.isWordOrExcel(url)) {
      const officeUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(officeUrl);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
