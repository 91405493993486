<main id="main">
  <section>
    <div class="container-fluid mb-5">
      <div class="row">
        <div class="col-12 mb-3">
          <h4 class="text-theme"><strong>Welcome Admin !</strong></h4>
        </div>
        <div class="col-12">
          <div class="col-12">
            <div class="col-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="radio-button" id="radioDaily" value="daily"
                  (change)="onDurationChange('daily')">
                <label class="form-check-label" for="radioDaily">Daily</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="radio-button" id="radioWeekly" value="weekly"
                  (change)="onDurationChange('weekly')">
                <label class="form-check-label" for="radioWeekly">Weekly</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="radio-button" id="radioMonthly" value="monthly"
                  (change)="onDurationChange('monthly')">
                <label class="form-check-label" for="radioMonthly">Monthly</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="radio-button" id="radioYearly" value="yearly"
                  (change)="onDurationChange('yearly')">
                <label class="form-check-label" for="radioYearly">Yearly</label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-xl-6 col-12">
          <div class="statistic-card">

            <div class="card-body card-header card-header-blue">
              <h5 class="text-theme"><strong>Projects Report</strong></h5><br>
              <div class="row">
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsPosted?.count
                      }}</h2>
                    <p>Projects Posted</p>
                    <!-- <p class="text-success">Value: £ 10M GBP</p> -->
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsPosted?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsMatched?.count
                      }}</h2>
                    <p>Projects Matched</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsMatched?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsClosed?.count
                      }}</h2>
                    <p>Projects Closed</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsClosed?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsInSolution?.count
                      }}</h2>
                    <p>In-Solution</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsInSolution?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsInSubmission?.count
                      }}</h2>
                    <p>In-Submission</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsInSubmission?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsInReview?.count
                      }}</h2>
                    <p>In-Review</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsInReview?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.TotalSubmitted?.count
                      }}</h2>
                    <p>Total Submitted</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.TotalSubmitted?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsAwarded?.count
                      }}</h2>
                    <p>Awarded</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsAwarded?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="ProjectStatistic">
                    <h2>{{superdashboardlist?.projectsNotAwarded?.count
                      }}</h2>
                    <p>Not Awarded</p>
                    <p class="text-success">Value: £ {{superdashboardlist?.projectsNotAwarded?.maxValue | number: '1.2-2'}} GBP</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-6 col-12">
          <div class="statistic-card">
            <div class="card-body">
              <h5 class="text-theme"><strong>Suppliers Statistics</strong></h5>
              <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th width="200px">Supplier Name</th>
                      <th>Last Login</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td *ngIf="!supplierUserList?.length" colspan="3">
                        <app-no-record-found></app-no-record-found>
                      </td>
                    </tr>

                    <tr
                      *ngFor="let item of supplierUserList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                      <td>{{item?.name}}</td>
                      <td>{{ item?.lastLogin ? (item.lastLogin | date : 'dd/MM/yyyy h:mm:ss a') : '-' }}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="col-lg-6 col-xl-6 col-12">
        <div class="statistic-card">
          <div class="card-body">
            <h5 class="text-theme mb-0"><strong>Category Wise Report</strong></h5>
            <div class="table-responsive mt-5 mb-3">
              <table class="table table-striped align-middle">
                <thead>
                  <tr>
                    <th width="200px">Category</th>
                    <th>Total Projects</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td *ngIf="!categoryWise?.length" colspan="2">
                      <app-no-record-found></app-no-record-found>
                    </td>
                  </tr>

                  <tr
                    *ngFor="let item of categoryWise | paginate: { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }; let i = index">
                    <td>{{ item?.name }}</td>
                    <td>{{ item?.totalProjects !== null ? item.totalProjects : '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main><!-- End #main -->