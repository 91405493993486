<main id="main">
  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/supplier-user-profile"><a>Profile</a></li>
            <li routerLink="/super-admin/admin-case-study-list"><a>Case Studies</a></li>
            <li routerLink="/super-admin/super-admin-supplier-users-list"><a>Users</a></li>
            <li routerLink="/super-admin/supplier-user-activity"><a class="active">Activity</a></li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-12">
          <h4 class="text-theme"><strong>Supplier Login Activity</strong></h4><br>
        </div>
      </div>
      <div class="row">
        <table border="1" style="width: 100%; text-align: center;">
          <thead>
            <tr>
              <th *ngFor="let date of dates">{{ date | date : 'dd/MM/yyyy' }}</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td *ngIf="!rows?.length" colspan="7">
                <app-no-record-found></app-no-record-found>
              </td>
            </tr>

            <tr *ngFor="let row of rows">
              <td *ngFor="let time of row">{{ time }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</main>