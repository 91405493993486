<main id="main">
  <section class="casestudy-section">
    <div class="container-fluid">
      <div class="sort-widget">
        <h6 class="p-600">Date Range</h6>
        <form class="row row-cols-sm-auto" (ngSubmit)="getDataByStatus()">
          <div class="col-6">
            <div class="input-group mb-2">
              <input class="form-control" placeholder="yyyy-mm-dd" [formControl]="trackerStartDate" ngbDatepicker
                #startDatePicker="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="startDatePicker.toggle()"
                type="button"></button>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" [formControl]="trackerEndDate" ngbDatepicker
                #endDatePicker="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="endDatePicker.toggle()"
                type="button"></button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="container-fluid">
      <div *ngIf="statusWiseData.length > 0">
        <h4 class="text-theme">Project Data</h4>
        <table class="table table table-striped table-bordered">
          <thead>
            <tr>
              <th>Status</th>
              <th>Project Count</th>
              <th>Project Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of statusWiseData">
              <td>{{ data.status }}</td>
              <td>{{ data.count }}</td>
              <td>{{ data.value | number:'1.0-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->

    <div class="container-fluid">

      <div class="row">
        <h5 class="text-theme"><strong>Feasibility Status</strong></h5>
        <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12 mb-4" *ngFor="let item of feasibilityData">
          <div class="FlatCard shadow border-radius6">
            <div class="card-body text-center p-3">
              <p class="text-bold">{{ item.status }}</p>
              <p class="text-bold">Project Count : {{ item.count }}</p>
              <p class="text-bold">Project Value : GBP {{ item.value| number:'1.0-2' }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <h5 class="text-theme"><strong>Bid Status</strong></h5>
        <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12 mb-4" *ngFor="let item of bidData">
          <div class="FlatCard shadow border-radius6">
            <div class="card-body text-center p-3">
              <p class="text-bold">{{ item.status }}</p>
              <p class="text-bold">Project Count : {{ item.count }}</p>
              <p class="text-bold">Project Value : GBP {{ item.value| number:'1.0-2' }}</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>


  </section>
</main>