<main id="main">
    <section class="bg-white">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-end">
                <div class="col-lg-2 col-xl-2 col-4 mob-mb1">
                    <a routerLink="/super-admin/super-admin-supplier" class="btn btn-primary text-dark">Back</a>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-borderless align-middle">
                    <thead>
                        <tr>
                            <th width="200px">Supplier Name</th>
                            <th>Supplier ID</th>
                            <th>Admin Name</th>
                            <th>Join Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{supplierData?.name}}</td>
                            <td v-align="middle">{{supplierData?._id}}</td>
                            <td>{{supplierData?.userName}}</td>
                            <td>{{supplierData?.doj | date : 'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input class="form-control control-search" placeholder="Search by BOS ID & Project Title."
                            type="text" [(ngModel)]="searchText" (input)="searchtext()">
                    </div>
                </div>
                <div class="col-lg-1 col-xl-1 col-12 mob-mb1" style="margin-right: 350px; cursor: pointer;">
                    <i class="bi bi-info-circle-fill" data-bs-toggle="tooltip"
                        title="Search using BOS ID, Project name, Client name, Website, Notice reference"></i>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-borderless table-transparent align-middle">
                    <thead>
                        <tr>
                            <th width="300px">Project Title</th>
                            <th>BOS ID</th>
                            <th>Launch Date</th>
                            <th>Category</th>
                            <th>Industry</th>
                            <th>Status</th>
                            <th>Min value (GBP)</th>
                            <th>Max value (GBP)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of projectList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                            <td><a href="javascript:;">{{item?.projectName}}</a></td>
                            <td v-align="middle">{{item?.BOSID}}</td>
                            <td>{{item?.publishDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{item?.category}}</td>
                            <td>{{item?.industry}}</td>
                            <td>{{item?.status}}</td>
                            <td>£ {{item?.minValue}}</td>
                            <td>£ {{item?.maxValue}}</td>
                            <td>
                                <a (click)="projectDetails(item?._id)" class="btn btn-sm btn-link"
                                    title="View Details">View Project Detils ></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 align-items-center">
                <div class="row">
                    <div class="pagination">
                        <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main><!-- End #main -->