<main id="main">
    <section class="pb-0" style="padding-top: 10px !important;">
        <div class="container-fluid text-center">
            <p style="text-align: center; font-weight: bold;">"We expect our suppliers to upload their case studies,
                because
                the projects will be matched according to the case studies. So, having more case studies will help you
                get
                more projects."
            </p>
        </div>
    </section>

    <section class="casestudy-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
                            <div class="form-group mb-0">
                                <button type="button" routerLink="/supplier-admin/add-casestudy"
                                    class="btn btn-primary me-2">ADD CASE STUDY</button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mt-5 mb-3">
                        <table class="table table-striped align-middle">
                            <thead>
                                <tr>
                                    <th width="100px">Project Name</th>
                                    <th>Category</th>
                                    <th>Industry</th>
                                    <th>Type</th>
                                    <th>Start Date</th>
                                    <th>Description</th>
                                    <th>Technology</th>
                                    <th>Maintenance</th>
                                    <th>Contract Duration</th>
                                    <th>Contract Value</th>
                                    <th>Resources Used</th>
                                    <th>Client Name</th>
                                    <!-- <th></th> -->
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td *ngIf="!caseStudyList?.length" colspan="13">
                                        <app-no-record-found></app-no-record-found>
                                    </td>
                                </tr>

                                <tr
                                    *ngFor="let item of caseStudyList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                                    <td>{{item?.name}}</td>
                                    <td>{{item?.category}}</td>
                                    <td>{{item?.industry}}</td>
                                    <td>{{item?.type}}</td>
                                    <td>{{item?.date | date:'dd/MM/yyyy'}}</td>
                                    <td><a title="{{ item?.description }}">{{ item?.description | slice:0:10 }}</a></td>
                                    <td>{{item?.technologies}}</td>
                                    <td>{{item?.maintenance}}</td>
                                    <td>{{item?.contractDuration}}</td>
                                    <td>{{item?.contractValue}}</td>
                                    <td>{{item?.resourcesUsed}}</td>
                                    <td>{{item?.clientName}}</td>
                                    <!-- <td>
                                        <span class="btn btn-primary btn-sm">
                                            <label for="uploadClientDocument"><i
                                                    class="bi bi-file-earmark-arrow-up-fill"></i>
                                            </label>
                                            <input type="file" id="uploadClientDocument" (change)="
                                                onFileChange($event, item.clientDocument)
                                              " name="myfile" style="height: 1px; width: 1px" />
                                        </span>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row mt-3 align-items-center">
                <div class="row">
                    <div class="pagination">
                        <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<div class="modal fade" id="ViewDocumentModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between;">
                <h5 class="modal-title" id="ViewDocumentModalLabel">Case Study ({{selectedDocument?.name}})</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="isPdf(selectedDocument?.link?.url); else otherFormats">
                    <ngx-extended-pdf-viewer [src]="selectedDocument?.link?.url" useBrowserLocale="true"
                        height="600px"></ngx-extended-pdf-viewer>
                </ng-container>
                <ng-template #otherFormats>
                    <iframe *ngIf="isWordOrExcel(selectedDocument?.link?.url)"
                        [src]="getDocumentViewerUrl(selectedDocument?.link?.url)"
                        style="width: 100%; height: 600px;"></iframe>
                    <img *ngIf="isImage(selectedDocument?.link?.url)" [src]="selectedDocument?.link?.url"
                        class="img-fluid" />
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ViewTemplate" tabindex="-1" aria-labelledby="ViewDocumentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between;">
                <h5 class="modal-title" id="ViewDocumentModalLabel">Case Study Template</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container>
                    <!-- <ngx-extended-pdf-viewer [src]="selectedCasestudy" useBrowserLocale="true"
                        height="600px"></ngx-extended-pdf-viewer> -->
                    <iframe *ngIf="isWordOrExcel(selectedCasestudy)" [src]="getDocumentViewerUrl(selectedCasestudy)"
                        style="width: 100%; height: 600px;"></iframe>
                </ng-container>
                <!-- <ng-template #otherFormats>
                    <iframe *ngIf="isWordOrExcel(selectedCasestudy)"
                        [src]="getDocumentViewerUrl(selectedCasestudy)"
                        style="width: 100%; height: 600px;"></iframe>
                    <img *ngIf="isImage(selectedCasestudy)" [src]="selectedCasestudy"
                        class="img-fluid" />
                </ng-template> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>