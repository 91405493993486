<main id="main">
  <section class="casestudy-section">
    <div class="container-fluid">
      <div class="row">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-10 col-12">
            <h4 class="text-theme"><strong>Edit Project Details</strong></h4>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="container-fluid">
      <form [formGroup]="supplierDetails" (ngSubmit)="submitForm()">
        <div class="row">
          <div class="col-lg-6 col-xl-6 col-12">
            <!-- Company Name -->
            <div class="row mb-3">
              <label for="companyName" class="col-sm-4 col-form-label">Company Name</label>
              <div class="col-sm-8">
                <input formControlName="companyName" type="text" class="form-control"
                  placeholder="Enter Company Name" />

              </div>
            </div>

            <!-- Website -->
            <div class="row mb-3">
              <label for="website" class="col-sm-4 col-form-label">Website</label>
              <div class="col-sm-8">
                <input formControlName="website" type="text" class="form-control" placeholder="Enter Website" />

              </div>
            </div>

            <!-- Year of Establishment -->
            <div class="row mb-3">
              <label for="yearOfEstablishment" class="col-sm-4 col-form-label">Year of Establishment</label>
              <div class="col-sm-8">
                <input formControlName="yearOfEstablishment" type="text" class="form-control"
                  placeholder="Enter Year of Establishment" />

              </div>
            </div>

            <!-- Registration Number -->
            <div class="row mb-3">
              <label for="registrationNumber" class="col-sm-4 col-form-label">Registration Number</label>
              <div class="col-sm-8">
                <input formControlName="registrationNumber" type="number" class="form-control"
                  placeholder="Enter Registration Number" />

              </div>
            </div>

            <!-- Type of Company -->
            <div class="row mb-3">
              <label for="TypeOfCompany" class="col-sm-4 col-form-label">Type of Company</label>
              <div class="col-sm-8">
                <input formControlName="TypeOfCompany" type="text" class="form-control"
                  placeholder="Enter Type of Company" />
              </div>
            </div>

            <!-- Industry Sector -->
            <div class="row mb-3">
              <label for="industry_Sector" class="col-sm-4 col-form-label">Industry Sector</label>
              <div class="col-sm-8">
                <input formControlName="industry_Sector" type="text" class="form-control"
                  placeholder="Enter Industry Sector" />
              </div>
            </div>

            <!-- Company Address -->
            <div class="row mb-3">
              <label for="companyAddress" class="col-sm-4 col-form-label">Company Address</label>
              <div class="col-sm-8">
                <textarea formControlName="companyAddress" class="form-control"
                  placeholder="Enter Company Address"></textarea>

              </div>
            </div>

            <div class="row mb-3">
              <label for="companyAddress" class="col-sm-4 col-form-label">Developer Or Engineer Teams</label>
              <div class="col-sm-8">
                <textarea formControlName="developerOrEngineerTeams" class="form-control"
                  placeholder="Enter Company Address"></textarea>

              </div>
            </div>
            <div class="row mb-3">
              <label for="companyAddress" class="col-sm-4 col-form-label">Data Privacy Policies</label>
              <div class="col-sm-8">
                <textarea formControlName="dataPrivacyPolicies" class="form-control"
                  placeholder="Enter Company Address"></textarea>

              </div>
            </div>
            <div class="row mb-3">
              <label for="securityCertifications" class="col-sm-4 col-form-label">
                Security Certifications
              </label>
              <div class="col-sm-8">
                <textarea formControlName="securityCertifications" class="form-control"
                  placeholder="Enter Security Certifications"></textarea>

              </div>
            </div>

            <!-- Email -->
            <div class="row mb-3">
              <label for="email" class="col-sm-4 col-form-label"> Email </label>
              <div class="col-sm-8">
                <input formControlName="email" type="email" class="form-control" placeholder="Enter Email" />

              </div>
            </div>

            <!-- Customer Support Contact -->
            <div class="row mb-3">
              <label for="customerSupportContact" class="col-sm-4 col-form-label">
                Customer Support Contact
              </label>
              <div class="col-sm-8">
                <input formControlName="customerSupportContact" type="text" class="form-control"
                  placeholder="Enter Customer Support Contact" />

              </div>
            </div>

            <!-- Company Contact Number -->
            <div class="row mb-3">
              <label for="companyContactNumber" class="col-sm-4 col-form-label">
                Company Contact Number
              </label>
              <div class="col-sm-8">
                <input formControlName="companyContactNumber" type="number" class="form-control"
                  placeholder="Enter Company Contact Number" />

              </div>
            </div>

            <!-- VAT or GST Number -->
            <div class="row mb-3">
              <label for="VATOrGSTNumber" class="col-sm-4 col-form-label">
                VAT or GST Number
              </label>
              <div class="col-sm-8">
                <input formControlName="VATOrGSTNumber" type="text" class="form-control"
                  placeholder="Enter VAT or GST Number" />

              </div>
            </div>

            <!-- Company Directors/Owners -->
            <div class="row mb-3">
              <label for="companyDirectors_Owners" class="col-sm-4 col-form-label">
                Company Directors/Owners
              </label>
              <div class="col-sm-8">
                <textarea formControlName="companyDirectors_Owners" class="form-control"
                  placeholder="Enter Company Directors/Owners"></textarea>

              </div>
            </div>
            <!-- Compliance Certifications -->
            <div class="row mb-3">
              <label for="complianceCertifications" class="col-sm-4 col-form-label">
                Compliance Certifications
              </label>
              <div class="col-sm-8">
                <textarea formControlName="complianceCertifications" class="form-control"
                  placeholder="Enter Compliance Certifications"></textarea>

              </div>
            </div>

            <!-- Products/Services Offered -->
            <div class="row mb-3">
              <label for="products_ServicesOffered" class="col-sm-4 col-form-label">
                Products/Services Offered
              </label>
              <div class="col-sm-8">
                <textarea formControlName="products_ServicesOffered" class="form-control"
                  placeholder="Enter Products/Services Offered"></textarea>

              </div>
            </div>

            <!-- Technology Stack -->
            <div class="row mb-3">
              <label for="technologyStack" class="col-sm-4 col-form-label">
                Technology Stack
              </label>
              <div class="col-sm-8">
                <textarea formControlName="technologyStack" class="form-control"
                  placeholder="Enter Technology Stack"></textarea>

              </div>
            </div>

            <!-- Licensing Details -->
            <div class="row mb-3">
              <label for="licensingDetails" class="col-sm-4 col-form-label">
                Licensing Details
              </label>
              <div class="col-sm-8">
                <textarea formControlName="licensingDetails" class="form-control"
                  placeholder="Enter Licensing Details"></textarea>

              </div>
            </div>

            <!-- IP/Patents -->
            <div class="row mb-3">
              <label for="IP_Patents" class="col-sm-4 col-form-label">
                IP/Patents
              </label>
              <div class="col-sm-8">
                <textarea formControlName="IP_Patents" class="form-control" placeholder="Enter IP/Patents"></textarea>

              </div>
            </div>

            <!-- Employee Count -->
            <div class="row mb-3">
              <label for="employeeCount" class="col-sm-4 col-form-label">
                Employee Count
              </label>
              <div class="col-sm-8">
                <input formControlName="employeeCount" type="number" class="form-control"
                  placeholder="Enter Employee Count" />

              </div>
            </div>

            <!-- Cybersecurity Practices -->
            <div class="row mb-3">
              <label for="cybersecurityPractices" class="col-sm-4 col-form-label">
                Cybersecurity Practices
              </label>
              <div class="col-sm-8">
                <textarea formControlName="cybersecurityPractices" class="form-control"
                  placeholder="Enter Cybersecurity Practices"></textarea>

              </div>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="row mt-4">
          <div class="col-12 text-end">
            <button type="button" routerLink="/super-admin/supplier-user-profile" class="btn btn-primary">
              Back</button>&nbsp;
            <button type="submit" class="btn btn-primary">Edit Supplier</button>
          </div>
        </div>
      </form>
    </div>
  </section>
</main>