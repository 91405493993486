<div class="container pt-5">
    <div class="row">
        <div class="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-12">
           <div class="sidebar-widget">
            <p class="ps-3"><strong>Task Status</strong></p>
                <ul class="sidebar-nav">
                    <li><a href="javascript:void(0)" class="active">Ongoing</a></li>
                    <li><a href="javascript:void(0)">Completed</a></li>
                    <li><a href="javascript:void(0)">Featured</a></li>
                </ul>
           </div>
        </div>
        <div class="col-lg-7 col-xl-7 col-md-8 col-sm-8 col-12">
            <div class="row">
                <div class="col-md-9 col-xl-9 col-12">
                    <ul class="taskuserList">
                        <li>Lorel Ipsum</li>
                        <li>Lorel Ipsum</li>
                        <li>Lorel Ipsum</li>
                        <li>Lorel Ipsum</li>
                        <li>Lorel Ipsum</li>
                    </ul>
                </div>
                <div class="col-md-3 col-xl-3 col-12">
                    <div class="dropdown">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                         Assign User
                        </button>
                        <ul class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <div class="form-check dropdown-item">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                  Mickel Morne
                                </label>
                              </div>                             
                          </li>
                          <li>
                            <div class="form-check dropdown-item">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                 Joseph D Symond
                                </label>
                              </div>
                          </li>
                        </ul>
                      </div>
                </div>
            </div>
            <div class="accordion accordion-flush" id="accordianTodoList">
                <p><strong>Ongoing Tak</strong></p>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-TodoListZero">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                          <span class="bluetext">+ Add a New Task</span>
                      </button>
                    </h2>                    
                  </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-TodoListOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <i class="bi bi-circle task-icon"></i> Microsoft Teams | Tasks in Teams Updates
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-TodoListOne" data-bs-parent="#accordianTodoList">
                    <div class="accordion-body">
                        <p>Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</p>
                        <ul class="SecondarytaskList">
                            <li> <i class="bi bi-circle task-icon"></i> Louisville Geek provides comprehensive managed IT services for a diverse range.</li>
                            <li> <i class="bi bi-circle task-icon"></i> Louisville Geek provides comprehensive managed IT services for a diverse range.</li>
                            <li> <i class="bi bi-circle task-icon"></i> Louisville Geek provides comprehensive managed IT services for a diverse range of businesses and non-profit organizations. We are passionate about IT and love what we do!</li>
                        </ul>
                    </div>
                  </div>
                </div>
                 <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-TodoListTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <i class="bi bi-circle task-icon"></i> Microsoft Teams | Tasks in Teams Updates
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-TodoListTwo" data-bs-parent="#accordianTodoList">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-TodoListThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <i class="bi bi-circle task-icon"></i> Microsoft Teams | Tasks in Teams Updates
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-TodoListThree" data-bs-parent="#accordianTodoList">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                  </div>
                </div>
              </div>
              <div class="accordion accordion-flush" id="accordianCompletedTodoList">
                <p><strong>Completed Tak</strong></p>   
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-TodoListOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <i class="bi bi-circle task-icon"></i> Microsoft Teams | Tasks in Teams Updates
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-TodoListOne" data-bs-parent="#accordianCompletedTodoList">
                    <div class="accordion-body">
                        <p>Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</p>
                        <ul class="SecondarytaskList">
                            <li> <i class="bi bi-circle task-icon"></i> Louisville Geek provides comprehensive managed IT services for a diverse range.</li>
                            <li> <i class="bi bi-circle task-icon"></i> Louisville Geek provides comprehensive managed IT services for a diverse range.</li>
                            <li> <i class="bi bi-circle task-icon"></i> Louisville Geek provides comprehensive managed IT services for a diverse range of businesses and non-profit organizations. We are passionate about IT and love what we do!</li>
                        </ul>
                    </div>
                  </div>
                </div>
                 <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-TodoListTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <i class="bi bi-circle task-icon"></i> Microsoft Teams | Tasks in Teams Updates
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-TodoListTwo" data-bs-parent="#accordianCompletedTodoList">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-TodoListThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <i class="bi bi-circle task-icon"></i> Microsoft Teams | Tasks in Teams Updates
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-TodoListThree" data-bs-parent="#accordianCompletedTodoList">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                  </div>
                </div>
              </div>
        </div>
        <div class="col-lg-3 col-xl-3 col-md-2 col-sm-3 col-12">
            <div class="sidebar-widget p-3">
                <ul class="Listgroup border-bottom bg-white">
                    <li> <i class="bi bi-circle task-icon bluetext"></i> Task 1 <span class="action-mark"><i class="bi bi-star bluetext"></i></span></li>
                    <li> <i class="bi bi-circle task-icon bluetext"></i> Task 1 <span class="action-mark"><i class="bi bi-x"></i></span></li>
                    <li> <a href="javascript:void(0)">+ New Step</a></li>
                </ul>
                   
                    <ul class="todo-actionList">
                        <li><a href="javascript:void(0)" class="active"><i class="bi bi-brightness-high me-2"></i> Add to my day</a></li>
                        <li><a href="javascript:void(0)"><i class="bi bi-bell me-2"></i> Reminder me</a></li>
                        <li><a href="javascript:void(0)"><i class="bi bi-calendar-week me-2"></i> Add due date</a></li>
                        <li><a href="javascript:void(0)"><i class="bi bi-repeat me-2"></i> Repeat</a></li>
                        <li><a href="javascript:void(0)"><i class="bi bi-pin me-2"></i> Pick a category</a></li>
                        <li><a href="javascript:void(0)"><i class="bi bi-paperclip me-2"></i> Add File</a></li>
                    </ul>
                    <textarea class="form-control" placeholder="Add note..." rows="2" cols="100"></textarea>
                    <div class="sidebar-footer-nav">
                        <div class="row ">
                            <div class="col-2"><a href="javascript:void(0)" class="action-button"><i class="bi bi-box-arrow-in-right"></i></a></div>
                            <div class="col-8 text-center">Created Today</div>
                            <div class="col-2 text-end"><a href="javascript:void(0)" class="action-button"></a> <i class="bi bi-trash"></i></div>
                        </div>
                    </div>
               </div>
        </div>
    </div>
</div>


<!-- Add New task Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New Task</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <textarea class="form-control" placeholder="Enter task details" cols="100" rows="3"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Add Task</button>
        </div>
      </div>
    </div>
  </div>