<main id="main">
  <section>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
          <div class="form-group mb-0">
            <h4 class="text-theme"><strong>Supplier List</strong></h4>
          </div>
        </div>
        <div class="col-lg-5 col-xl-5 col-md-5 col-12 d-flex justify-content-end gap-2 mb-2">
          <a routerLink="/super-admin/add-new-supplier" class="btn btn-primary btn-sm">
            <i class="bi bi-plus-square"> </i>  Register Supplier
          </a>
        </div>
      </div>
      <div class="table-responsive mt-5 mb-3">
        <table class="table table-striped align-middle">
          <thead>
            <tr>
              <th width="200px">Supplier Name</th>
              <th>Join Date</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of supplierUserList | paginate: { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }; let i = index">
              <td>{{item?.name}}</td>
              <td>{{item?.doj | date : 'dd/MM/yyyy'}}</td>
              <td>
                <a href="javascript:;" (click)="projectDetails(item?._id ,item)" class="btn btn-sm btn-primarysm"
                  title="View Details">
                  View Project
                </a>
              </td>
              <td>
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="item.active" (ngModelChange)="onToggleSwitch(item)">
                  <span class="slider round"></span>
                </label>
              </td>
              <td> <a (click)="deleteSupplier(item?._id)" class="btn btn-danger btn-sm"><i
                    class="bi bi-trash3-fill"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="row">
          <div class="pagination">
            <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>